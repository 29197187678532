import { spring } from 'motion'
import { mergeProps, type ParentComponent, type ParentProps } from 'solid-js'
import { Motion } from 'solid-motionone'

type FadeUpProps = {
  delay?: number
  duration?: number
} & ParentProps

export const FadeUp: ParentComponent<FadeUpProps> = (props) => {
  const merged = mergeProps(
    {
      delay: 0,
      duration: 0.5,
    },
    props,
  )
  return (
    <Motion
      initial={{ opacity: 0, y: 15 }}
      inView={{ opacity: 1, y: 0 }}
      transition={{
        delay: merged.delay,
        duration: merged.duration,
        easing: spring(),
      }}
    >
      {props.children}
    </Motion>
  )
}
